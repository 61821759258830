@import url(https://fonts.googleapis.com/css2?family=Pacifico&display=swap);
body {
  box-sizing: border-box;
}

.section {
  padding: 5rem 0;
}

.section_center {
  width: 90vw;
  margin: 2rem auto 2rem auto;
  max-width: 1200px;
}

.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
}
.loader {
  color: #3e3f3a;
  font-size: 11px;
  text-indent: -99999em;
  margin: 55px auto;
  position: relative;
  width: 10em;
  height: 10em;
  box-shadow: inset 0 0 0 1em;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}
.loader:before,
.loader:after {
  position: absolute;
  content: '';
}
.loader:before {
  width: 5.2em;
  height: 10.2em;
  background: #fff;
  border-radius: 10.2em 0 0 10.2em;
  top: -0.1em;
  left: -0.1em;
  -webkit-transform-origin: 5.1em 5.1em;
  transform-origin: 5.1em 5.1em;
  -webkit-animation: load2 2s infinite ease 1.5s;
  animation: load2 2s infinite ease 1.5s;
}
.loader:after {
  width: 5.2em;
  height: 10.2em;
  background: #fff;
  border-radius: 0 10.2em 10.2em 0;
  top: -0.1em;
  left: 4.9em;
  -webkit-transform-origin: 0.1em 5.1em;
  transform-origin: 0.1em 5.1em;
  -webkit-animation: load2 2s infinite ease;
  animation: load2 2s infinite ease;
}
@-webkit-keyframes load2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.hero {
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url(/static/media/games-banner.8b8dde36.jpg) center/cover no-repeat;
}

.hero_banner {
  text-align: center;
  padding: 0 2rem;
}

.hero_title {
  color: rgb(201, 120, 66);
}

.hero_text {
  width: 85%;
  margin: 0 auto;
  margin-bottom: 2rem;
  color: rgb(163, 197, 240);
  font-weight: 900;
}

@media screen and (min-width: 800px) {
  .hero_title {
    font-size: 4.5rem;
  }

  .hero_text {
    width: 70%;
  }
}

.logo {
  color: #f39c12 !important;
  font-weight: 600;
  font-family: 'Pacifico', cursive;
}

.event_card {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin: 0 auto;
  box-shadow: 0 3px 7px -1px rgba(0, 0, 0, 0.1);
  margin-bottom: 2%;
  background: #fff;
  line-height: 1.4;
  border-radius: 5px;
  overflow: hidden;
  z-index: 0;
}

.event_card a {
  color: inherit;
}

.event_card a:hover {
  color: #5ad67d;
}

.event_card:hover .event_img {
  -webkit-transform: scale(1.3) rotate(3deg);
          transform: scale(1.3) rotate(3deg);
}

.event_meta {
  position: relative;
  z-index: 0;
  height: 200px;
}

.event_img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-position: center;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}

.event_details {
  margin: auto;
  padding: 0;
  list-style: none;

  position: absolute;
  top: 0;
  bottom: 0;
  left: -100%;
  margin: auto;
  transition: left 0.2s;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  padding: 10px;
  width: 100%;
  font-size: 0.9rem;
}

.event_details .event_creator::before {
  font-family: 'Font Awesome 5 Free';
  margin-right: 10px;
  content: '\f007';
}

.event_details .event_date::before {
  font-family: 'Font Awesome 5 Free';
  margin-right: 10px;
  content: '\f133';
}

.event_details .event_time::before {
  font-family: 'Font Awesome 5 Free';
  margin-right: 10px;
  content: '\f017';
}

.event_card:hover .event_details {
  left: 0%;
}

.event_info {
  padding: 1rem;
  background: #fff;
  position: relative;
  z-index: 1;
}

.event_title {
  line-height: 1;
  margin: 0;
  font-size: 1.7rem;
}

.event_subtitle {
  font-size: 1rem;
  font-weight: 300;
  text-transform: uppercase;
  color: #a2a2a2;
  margin-top: 5px;
}

.attend {
  text-align: right;
}

.event_card p {
  position: relative;
  margin: 1rem 0 0;
}

.event_desc {
  margin-top: 1.25rem;
}

.event_desc span {
  font-style: italic;
}

.event_desc::before {
  content: '';
  position: absolute;
  height: 5px;
  background: #20c997;
  width: 35px;
  top: -0.75rem;
  border-radius: 3px;
}
/* 
.btn-attend {
  background: #20c997;
  color: white;
} */

@media screen and (min-width: 640px) {
  .event_card {
    -webkit-flex-direction: row;
            flex-direction: row;
    max-width: 700px;
  }

  .event_meta {
    -webkit-flex-basis: 40%;
            flex-basis: 40%;
    height: auto;
  }

  .event_info {
    -webkit-flex-basis: 60%;
            flex-basis: 60%;
  }

  .event_info::before {
    -webkit-transform: skewX(-3deg);
            transform: skewX(-3deg);
    content: '';
    background: #fff;
    width: 30px;
    position: absolute;
    left: -10px;
    top: 0;
    bottom: 0;
    z-index: -1;
  }
}

.EventsList_events_list__XyonC {
  background: #f1f1f1;
}

.Homepage_events_list__LkfVB {
  background: #f6f7f8;
}

.Homepage_title__13_fe {
  text-align: center;
  text-transform: uppercase;
  font-family: 'Pacifico', cursive;
  letter-spacing: 2px;
  color: #18bc9c;
}

/* @media screen and (min-width: 700px) {
  .nav_tabs {
    background: #dee5ec;
  }
} */

.EventDetail_event__section__39LD- {
  min-height: 100vh;
  background: #f7f7f7;
  padding: 2rem 1rem !important;
}

.EventDetail_event__section__center__RCY8J {
  margin: 2rem auto 2rem auto;
}

.EventDetail_event__details__1FyST {
  position: relative;
  box-shadow: 2px 5px 3px 0px rgba(0, 0, 0, 0.15);
}

.EventDetail_sed__background__3oLUl {
  height: 50vh;
  background-repeat: no-repeat;
  background-size: cover;
}

.EventDetail_date__box__1uOBx {
  position: absolute;
  display: -webkit-flex;
  display: flex;
  right: 10px;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  color: rgb(26, 23, 23);
  border: 2px solid;
  font-weight: bold;
  padding: 5px 10px;
}

.EventDetail_date__day__3hvUP {
  font-size: 22px;
}

.EventDetail_date__month__MyYMm {
  text-transform: uppercase;
}

.EventDetail_sed__header__2k95a {
  padding: 20px 15px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  background: white;
}

.EventDetail_event__location__3ln5B {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}

.EventDetail_event__location__3ln5B span {
  font-style: italic;
  font-size: smaller;
  color: rgb(31, 139, 175);
}

.EventDetail_sed__info__1gsGm {
  background: white;
  padding: 10px 20px 40px;
}

.EventDetail_sed__info__1gsGm p {
  line-height: 1.1;
}

.EventDetail_event__icon__2xR6P {
  margin-right: 0.5rem;
}

.EventDetail_btn__group__iWlJf {
  margin-top: 2rem;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}

.EventDetail_login__VVbR4 span {
  cursor: pointer;
  color: lightseagreen;
}

.EventDetail_login__VVbR4 span:hover {
  text-decoration: underline;
}

@media screen and (min-width: 768px) {
  .EventDetail_event__section__39LD- {
    padding: 3rem 6rem !important;
  }

  .EventDetail_sed__header__2k95a {
    padding: 20px 40px;
  }

  .EventDetail_date__box__1uOBx {
    right: 35px;
  }

  .EventDetail_sed__info__1gsGm {
    padding: 10px 40px 40px;
  }
}

.datetime-picker {
  width: 100% !important;
  display: block;
}

.form-group label {
  display: block;
}

.new-event {
  min-height: 100vh;
  background-color: #f7f7f7;
}

.btn-group {
  margin-top: 2rem;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}

.new-event-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 70%;
  margin: 0 auto;
  /* padding: 2rem 0rem; */
}

.new-event-form {
  min-height: 50vh;
}

@media screen and (min-width: 600px) {
  .new-event-form {
    min-width: 600px;
    padding: 3rem 2rem;
  }
}

/* .edit-event-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.edit-event-wrapper form {
  width: 600px;
}

 */

.edit-buttons {
  margin-top: 2rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.profile-img {
  border-radius: 50%;
}

.my-profile {
  padding: 2rem;
}

.profile-img-container img {
  display: block;
  width: 100px;
  height: 100px;
  margin: 0 auto;
}

.profile-header h3 {
  display: none;
  font-family: 'Pacifico', cursive;
  color: #80a1a1;
}

@media screen and (min-width: 450px) {
  .submit-btn-wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
  }
}

@media screen and (min-width: 992px) {
  .profile-header h3 {
    display: block;
    margin-bottom: 3rem;
    font-size: 2.6rem;
  }
}

.section_dashboard {
  min-height: 100vh;
  /* background-color: burlywood; */
  margin: 1rem auto;
}

/* .dashboard {
  background-color: rgb(229, 226, 231);
} */

.profile-container {
  background-color: rgb(238, 243, 243);
}

.myevents-container {
  /* background-color: rgb(238, 243, 243); */
  margin-top: 5rem;
}

