.hero {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url('../../../../images/games-banner.jpg') center/cover no-repeat;
}

.hero_banner {
  text-align: center;
  padding: 0 2rem;
}

.hero_title {
  color: rgb(201, 120, 66);
}

.hero_text {
  width: 85%;
  margin: 0 auto;
  margin-bottom: 2rem;
  color: rgb(163, 197, 240);
  font-weight: 900;
}

@media screen and (min-width: 800px) {
  .hero_title {
    font-size: 4.5rem;
  }

  .hero_text {
    width: 70%;
  }
}
